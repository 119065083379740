import React from 'react';

import SVGIcon, { SvgProps } from '.';

export const SwitchToDocumentSVG = (svgProps: SvgProps) => {
  return (
    <SVGIcon viewBox={{ x: 0, y: 0, width: 24, height: 24 }} {...svgProps}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#055FD2"
          fillRule="nonzero"
          d="M8.856 1.477l4.107 4.106 1.292-1.292-1.412-1.412c4.382.376 7.893 3.841 8.324 8.204H23C22.413 4.025 15.474-.53 8.856 1.477zm.889 18.232l1.412 1.412c-4.382-.376-7.893-3.841-8.324-8.204H1c.587 7.058 7.526 11.614 14.144 9.606l-4.107-4.106-1.292 1.292z"
        />
        <path
          fill="#055FD2"
          d="M16.5 6h-9C6.675 6 6 6.675 6 7.5v9c0 .825.675 1.5 1.5 1.5h9c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5zm-6.375 5.625c0 .623-.502 1.125-1.125 1.125h-.75v.938a.567.567 0 01-.563.562.567.567 0 01-.562-.563V10.5c0-.412.338-.75.75-.75H9c.623 0 1.125.502 1.125 1.125v.75zm3.75 1.5c0 .623-.502 1.125-1.125 1.125h-1.5a.371.371 0 01-.375-.375v-3.75c0-.21.165-.375.375-.375h1.5c.623 0 1.125.502 1.125 1.125v2.25zm3-2.813a.567.567 0 01-.563.563h-.562v.75h.563c.307 0 .562.255.562.563a.567.567 0 01-.563.562h-.562v.938a.567.567 0 01-.563.562.567.567 0 01-.562-.563V10.5c0-.412.338-.75.75-.75h.938c.307 0 .562.255.562.563zM8.25 11.626H9v-.75h-.75v.75zm3.75 1.5h.75v-2.25H12v2.25z"
        />
      </g>
    </SVGIcon>
  );
};
