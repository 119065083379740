import React from 'react';

import SVGIcon, { SvgProps } from '.';

export const WebinarSVG = (svgProps: SvgProps) => {
  return (
    <SVGIcon viewBox={{ x: 0, y: 0, width: 25, height: 25 }} {...svgProps}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M8.80890072,2.46538519 C3.94638752,2.46538519 0,6.41177271 0,11.2742859 C0,16.1367991 3.94638752,20.0831866 8.80890072,20.0831866 C13.6714139,20.0831866 17.6178014,16.1367991 17.6178014,11.2742859 C17.6178014,6.41177271 13.6714139,2.46538519 8.80890072,2.46538519 Z M6.60667554,13.6262624 L6.60667554,8.92230941 C6.60667554,8.22640625 7.3818588,7.80357902 7.96324625,8.18236175 L11.61894,10.5343382 C12.156283,10.8778854 12.156283,11.6706864 11.61894,12.0142336 L7.96324625,14.3662101 C7.3818588,14.7449928 6.60667554,14.3221655 6.60667554,13.6262624 Z"
          id="Fill-1"
        ></path>
        <path
          d="M3.83883084,17.8761165 L2.90209234,16.6317712 C2.2605401,16.0873812 2.40007309,16.9591981 2.6403799,15.5954921 L2.7341066,15.0636107 C2.97441342,13.6999048 5.71239594,14.947069 7.07610186,15.1873758 L8.50842911,15.0252039 C9.87213503,15.2655107 10.9876942,14.6091595 10.7473874,15.9728655 L10.6536607,16.5047469 C10.4687619,17.5537108 10.8656029,19.0342227 10.4380188,19.5523623 C10.6382451,19.7515315 10.6662574,19.9135272 10.238321,19.9194292 C9.10752245,19.9350209 8.17580502,20.3394375 8.17580502,20.3394375 L8.18346877,20.329043 C8.18346877,20.329043 5.58827853,21.6443 4.4257679,22.2333512 C4.32129434,22.2862927 4.19585559,22.275722 4.10177653,22.2059555 C4.00769747,22.136189 3.96109839,22.0192068 3.98144695,21.9038983 C4.13533844,21.0308482 4.40022209,19.5277854 4.40022209,19.5277854 C4.41378779,19.3337253 4.4353696,18.5012842 3.8511633,18.1542135 C3.66203621,18.0419001 3.6941006,17.9501113 3.83883084,17.8761165 Z"
          id="Fill-2"
        ></path>
        <path
          d="M18.1027314,17.926063 C18.2257918,18.0150329 18.3797713,18.0500042 18.5291703,18.023049 C18.6785693,17.9960057 18.8105266,17.9093261 18.8946516,17.7829184 C20.1777561,15.8080509 20.8654669,13.500207 20.8654669,11.1368671 C20.8654669,8.09022065 19.7235691,5.16134925 17.6768211,2.92230287 C17.5795708,2.81800548 17.4440899,2.75766451 17.3014738,2.7551193 C17.1587696,2.75273153 17.0212627,2.80831569 16.9204008,2.9091776 C16.9041043,2.92256713 16.8892173,2.93754226 16.8744183,2.95234122 C16.6740158,3.15344842 16.6684662,3.47699934 16.8619097,3.68480131 C18.7167118,5.71666235 19.7508767,8.37342681 19.7508767,11.1368671 C19.7508767,13.2803369 19.1281756,15.3735079 17.9642555,17.1643574 C17.7989124,17.4132969 17.85987,17.7483875 18.102291,17.9232442 C18.1016743,17.9252702 18.1022029,17.9256226 18.1027314,17.926063 Z"
          id="Fill-3"
        ></path>
        <path
          d="M20.4135703,20.228093 C20.5245625,20.3084302 20.6633908,20.3404946 20.7985193,20.3169748 C20.9335598,20.2934551 21.0533608,20.2163772 21.130791,20.1032709 C22.9104533,17.4557558 23.86525,14.3347623 23.86525,11.1368671 C23.86525,7.05226788 22.3088934,3.12807879 19.524488,0.157277019 C19.4304089,0.0584411532 19.3003896,0.00171183262 19.1638516,0 C19.0273136,-0.00163554965 18.8959729,0.0518344777 18.7994274,0.14838003 C18.7748505,0.171018904 18.7511546,0.194714847 18.7275467,0.218234612 C18.5333105,0.413175585 18.5295227,0.727300985 18.7190902,0.926822586 C21.3063524,3.69026283 22.7520692,7.3389976 22.7520692,11.1368671 C22.7520692,14.1096068 21.8655414,17.0109063 20.2114942,19.4720251 C20.0564575,19.6990305 20.1106322,20.0081348 20.3334974,20.1688972 C20.3596599,20.1891577 20.3867032,20.2087135 20.4135703,20.228093 Z"
          id="Fill-4"
        ></path>
      </g>
    </SVGIcon>
  );
};
