import React from 'react';

import SVGIcon, { SvgProps } from '.';

export const ExitCrossSVG = (svgProps: SvgProps) => {
  return (
    <SVGIcon viewBox={{ x: 0, y: 0, width: 512, height: 512 }} {...svgProps}>
      <path d="M256.010 204.645l100.118-100.146 51.344 51.33-100.118 100.146-51.344-51.329z M155.827 407.483l-51.344-51.358 100.161-100.132 51.344 51.358-100.161 100.132z M407.498 356.112l-51.373 51.358-100.118-100.146 51.373-51.358 100.118 100.146z M104.502 155.857l51.337-51.351 100.153 100.125-51.337 51.351-100.153-100.125z M255.983 307.36l-51.351-51.365 51.365-51.351 51.351 51.365-51.365 51.351z" />
    </SVGIcon>
  );
};
